/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import functions from "./functions.js";

interface DealDto {
  closingCosts: number;
  downPayment: string;
  interestRate: number;
  term: number;
  rehab: number;
  grossRent: string;
  otherIncome: number;
  electricity: number;
  waterSewer: number;
  garbage: number;
  hoa: number;
  monthlyInsurance: number;
  floodInsurance: number;
  otherInsurance: number;
  currentPropertyTaxes: number;
  estimatedPropertyTaxes: number;
  lawnCare: number;
  vacancy: number;
  capital: number;
  repairs: number;
  management: number;
  requiredProfit: number;
}

interface CalculatedDto {
  humanOffer: string;
  totalCash: number;
  presentValueOfLoan: number;
  mortgagePayment: number;
  cashFlow: number;
  yearlyProfit: number;
  totalCocReturn: number;
  totalIncome: number;
  grossRent: number;
  fixedExpenses: number;
  totalVariableExpenses: number;
  vacancyExpense: number;
  repairExpense: number;
  capitalExpense: number;
  managementExpense: number;
  dto: SpecificDealDto;
}

interface SpecificDealDto {
  closingCosts: number;
  downPayment: string;
  interestRate: number;
  term: number;
  rehab: number;
  grossRent: number;
  otherIncome: number;
  electricity: number;
  waterSewer: number;
  garbage: number;
  hoa: number;
  monthlyInsurance: number;
  floodInsurance: number;
  otherInsurance: number;
  currentPropertyTaxes: number;
  estimatedPropertyTaxes: number;
  lawnCare: number;
  vacancy: number;
  capital: string;
  repairs: string;
  management: number;
  requiredProfit: number;
  mortgagePayment: number;
  totalValue?: number;
}

interface CashflowSeriesParams {
  rentGrowth: number;
  maintGrowth: number;
  appreciation: number;
}

function cashflow(dto: SpecificDealDto, overrides: unknown): SpecificDealDto {
  //console.log("cashflow ", JSON.stringify(dto));
  const process: SpecificDealDto = JSON.parse(JSON.stringify(dto));
  Object.assign(process, overrides);

  const monthlyIncome = process.grossRent + process.otherIncome;

  const vacancyExpense = (process.vacancy / 100.0) * monthlyIncome;
  const repairExpense = functions.getMonthlyValue(
    monthlyIncome,
    process.repairs
  );
  const capitalExpense = functions.getMonthlyValue(
    monthlyIncome,
    process.capital
  );
  const managementExpense = functions.pennyRound(
    (process.management / 100) * monthlyIncome
  );

  const cashflow = functions.pennyRound(
    monthlyIncome -
      vacancyExpense -
      repairExpense -
      capitalExpense -
      process.mortgagePayment -
      managementExpense -
      process.electricity -
      process.waterSewer -
      process.garbage -
      process.hoa -
      process.monthlyInsurance -
      process.floodInsurance -
      process.otherInsurance -
      process.estimatedPropertyTaxes
  );

  process.requiredProfit = cashflow;
  process.repairs = "$" + repairExpense;
  process.capital = "$" + capitalExpense;

  return process;
}

function cashflowSeries(
  out: CalculatedDto,
  overrides: unknown,
  params: CashflowSeriesParams
) {
  console.log(out, overrides, params);
  // let monthlyRentChange = Math.pow(1 + params.rentGrowth / 100, 1 / 12);
  // let monthlyMaintChange = Math.pow(1 + params.maintGrowth / 100, 1 / 12);
  // let monthlyAppreciation = Math.pow(1 + params.appreciation / 100, 1 / 12);

  // console.log(
  //   params,
  //   monthlyRentChange,
  //   monthlyMaintChange,
  //   monthlyAppreciation
  // );

  const years = [];
  for (let i = 0; i <= 15; i++) {
    const yearlyInsuranceScaled = Math.pow(1 + params.appreciation / 100, i);
    const totRentScaled = Math.pow(1 + params.rentGrowth / 100, i);
    const totMaintScaled = Math.pow(1 + params.maintGrowth / 100, i);
    const totAppr = Math.pow(1 + params.appreciation / 100, i);

    const grossRent = out.dto.grossRent * totRentScaled;
    const monthlyIncome = out.dto.otherIncome * totAppr + grossRent;

    const repairExpense = functions.getMonthlyValue(
      monthlyIncome,
      out.dto.repairs,
      totMaintScaled
    );

    const capitalExpense = functions.getMonthlyValue(
      monthlyIncome,
      out.dto.capital,
      totMaintScaled
    );

    const electricity = out.dto.electricity * totMaintScaled;
    const waterSewer = out.dto.waterSewer * totMaintScaled;
    const garbage = out.dto.garbage * totMaintScaled;
    const hoa = out.dto.hoa * totMaintScaled;
    const monthlyInsurance = yearlyInsuranceScaled * out.dto.monthlyInsurance;
    const floodInsurance = yearlyInsuranceScaled * out.dto.floodInsurance;
    const otherInsurance = yearlyInsuranceScaled * out.dto.otherInsurance;
    const propertyTaxes = totAppr * out.dto.estimatedPropertyTaxes;

    const cfInputs: SpecificDealDto = {
      grossRent: grossRent,
      repairs: "$" + repairExpense,
      capital: "$" + capitalExpense,
      electricity: electricity,
      waterSewer: waterSewer,
      garbage: garbage,
      hoa: hoa,
      monthlyInsurance: monthlyInsurance,
      floodInsurance: floodInsurance,
      otherInsurance: otherInsurance,
      estimatedPropertyTaxes: propertyTaxes,
      closingCosts: out.dto.closingCosts,
      downPayment: out.dto.downPayment,
      interestRate: out.dto.interestRate,
      term: out.dto.term,
      rehab: out.dto.rehab,
      otherIncome: out.dto.otherIncome,
      currentPropertyTaxes: out.dto.currentPropertyTaxes,
      lawnCare: out.dto.lawnCare,
      vacancy: out.dto.vacancy,
      management: out.dto.management,
      requiredProfit: out.dto.requiredProfit,
      mortgagePayment: out.mortgagePayment,
    };

    years.push(cashflow(cfInputs, {}));
  }

  return years;
}

export default {
  cashflow: cashflow,
  cashflowSeries: cashflowSeries,
};
