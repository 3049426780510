<template>
  <div id="load">
    <h3>Parcels</h3>
    <div v-if="loading">Loading...</div>
    <div v-else>
      Found {{ items.length }} parcels.
      <ul>
        <li v-for="item in items" v-bind:key="item.id">
          <a target="_blank" :href="item.Website">{{ item.id }}</a>

          {{ item["Market Value"] }}

          {{ item.Address }}
          <a
            target="_blank"
            :href="'https://google.com/search?q=' + item.Address"
            >(goog)</a
          >

          {{ item.Owner }}
          <a
            target="_blank"
            :href="'https://google.com/search?q=' + item.Owner"
          >
            (goog)
          </a>
          &nbsp;
          <a
            target="_blank"
            :href="
              'http://www.co.pueblo.co.us/cgi-bin/webatrbroker.wsc/t_info.p?parcel=' +
              item.id +
              '&compres=Additional%20Treasurer%20Information'
            "
            >(treasury)</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import $ from "jquery";

export default {
  watch: {
    $route(from, to) {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let me = this;
      me.loading = true;
      me.items = [];
      $.get("/treasuryParcels", function (data) {
        me.items = data;
        me.loading = false;
      });
    },
  },
  data: function () {
    return {
      items: [],
      loading: true,
    };
  },
};
</script>

<style src="../../public/calc.css" />
