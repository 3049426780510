<template>
  <div>
    <table>
      <tr>
        <th v-for="head in headers()" v-bind:key="head">
          {{ head }}
        </th>
      </tr>
      <tr v-for="(row, id) in rotated()" :key="id">
        <td v-for="(cell, cid) in row" v-bind:key="cid">
          {{ cell }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ["data"],
  methods: {
    headers() {
      return this.rotatedHelper[0];
    },
    rotated() {
      const out = [];
      const raw = this.rotatedHelper;
      console.log("rotated:", raw);
      for (let i = 1; i < raw.length; i++) {
        out.push(raw[i]);
      }
      return out;
    },
  },
  computed: {
    rotatedHelper() {
      const out = [];

      const inWidth = this.data[0].length;
      const inHeight = this.data.length;

      for (let i = 0; i < inWidth; i++) {
        out.push(new Array(inHeight));
      }

      for (let i = 0; i < inWidth; i++) {
        for (let j = 0; j < inHeight; j++) {
          out[i][j] = this.data[j][i];
        }
      }

      return out;
    },
  },
};
</script>
