<template>
  <div id="results">
    <Chart v-if="false" />

    <div v-if="loading">Loading...</div>
    <div v-else>
      <div>
        <h3>
          Summary
          <router-link :to="{ name: 'edit', params: { id: $route.params.id } }"
            >(edit)</router-link
          >
          <router-link :to="{ name: 'calc', params: { id: $route.params.id } }"
            >(view)</router-link
          >
        </h3>

        <img v-if="dto.imgsrc != null" :src="dto.imgsrc" class="imgRight" />
        Address: <br />
        <span>{{ dto.address }}</span> <br />

        <a target="_blank" :href="'https://google.com/search?q=' + dto.address"
          >Google</a
        >
        <br />
        <br />

        Description: <br />
        <span v-html="renderStr(dto.description)"></span>
        <br />
        <br />

        <br />

        Notes: <br />
        <span class="notes">{{ dto.notes }}</span>
        <br /><br />

        <div v-if="dto.mls != null && dto.mls.length > 0">
          MLS: <span>{{ dto.mls }}</span>
          <a
            target="_blank"
            :href="'https://google.com/search?q=MLS+' + dto.mls"
            >Google</a
          ><br />
        </div>

        Source: <a :href="dto.source" target="_blank">{{ dto.source }}</a
        ><br />
        Rentometer: <a :href="dto.rlink" target="_blank">{{ dto.rlink }}</a
        ><br />

        Asking: <span>{{ dto.asking }}</span>

        <table id="resultSummary">
          <thead>
            <tr>
              <th></th>

              <th v-for="p in profits" v-bind:key="p">$ {{ p }} Profit</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="results in resultSet.resultSet"
              v-bind:key="results[0].totalIncome"
            >
              <th>$ {{ results[0].totalIncome.toLocaleString() }} Income</th>

              <td
                v-for="out in results"
                v-bind:key="out.id"
                @click="show(out)"
                class="iosClickable"
              >
                {{ out.humanOffer.toLocaleString() }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!--<input id="showAllResults" class="check" type="checkbox" v-model="showAll" /> <label for="showAllResults" class="check">Show All Results?</label>-->

      <div
        v-for="results in resultSet.resultSet"
        v-bind:key="results[0].totalIncome"
      >
        <hr style="clear: both" v-if="showAll" />
        <div v-for="out in results" v-bind:key="out.id" class="resultDetail">
          <div v-if="showAll || (showId == out.id && showId != null)">
            <h2>{{ out.dto.requiredProfit }} Profit</h2>
            <h3>Purchase Details</h3>

            Total Offer: {{ out.humanOffer }} <br />
            Total Cash: {{ out.totalCash.toFixed(2) }} <br />
            Down Payment: {{ out.dto.downPayment }} <br />
            Closing Costs: {{ out.dto.closingCosts }} <br />
            Rehab Costs: {{ out.dto.rehab }} <br />
            Loan Amount: {{ out.presentValueOfLoan }} <br />
            Interest Rate: {{ out.dto.interestRate }} <br />
            Term: {{ out.dto.term }} years <br />

            <h3>Cash Flow Analysis</h3>
            Mortgage Payment: {{ out.mortgagePayment.toFixed(2) }} <br />
            Monthly Profit: {{ out.cashFlow.toFixed(2) }} <br />
            Yearly Profit: {{ out.yearlyProfit.toFixed(2) }} <br />
            Cash on Cash Return: {{ out.totalCocReturn.toFixed(2) }} <br />

            <h3>Total Income: {{ out.totalIncome }}</h3>
            Gross Rent: {{ out.dto.grossRent }} <br />
            Other Income: {{ out.dto.otherIncome }} <br />

            <h3>Expenses</h3>
            <h4>Fixed: {{ out.fixedExpenses }}</h4>
            Electricity: {{ out.dto.electricity }} <br />
            Water/Sewer: {{ out.dto.waterSewer }} <br />
            Garbage: {{ out.dto.garbage }} <br />
            HOA: {{ out.dto.hoa }} <br />
            Monthly Insurance: {{ out.dto.monthlyInsurance }} <br />
            Flood Insurance: {{ out.dto.floodInsurance }} <br />
            Other Insurance: {{ out.dto.otherInsurance }} <br />
            Property Taxes (Est): {{ out.dto.estimatedPropertyTaxes }} <br />

            <h4>Variable: {{ out.totalVariableExpenses }}</h4>
            Vacancy Expense: {{ out.vacancyExpense.toFixed(2) }} <br />
            Repair Expense: {{ out.repairExpense }} <br />
            Capital Expense: {{ out.capitalExpense }} <br />
            Management Expense: {{ out.managementExpense }} <br />

            <br />
          </div>
        </div>
      </div>
    </div>

    <div v-if="!showAll">
      <h3>Monthly Snapshot</h3>

      <div class="monthlySummary">
        <HorizontalTable v-bind:data="cashflowChart(detailView)" />
      </div>

      <div class="thirdChart">
        <Chart
          v-bind:chartData="cashflowChart(detailView)"
          title="Cashflow and Expenses"
          subtitle="Monthly Snapshot, beginning of year"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-this-alias */
//import Vue from "vue";
import $ from "jquery";
import func from "@/modules/functions.js";
import Chart from "@/components/Chart.vue";
import af from "@/modules/analysisFunctions.ts";
import HorizontalTable from "@/components/HorizontalTable.vue";

export default {
  components: {
    Chart,
    HorizontalTable,
  },
  watch: {
    $route(from, to) {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    cashflowChart(out) {
      if (out === null) {
        return null;
      }

      const cfs = this.cashflowSeries(
        out,
        { mortgagePayment: out.mortgagePayment },
        null
      );

      const chartData = [
        ["Year", "Rent", "Other Income", "Cash Flow", "Repairs", "Capex"],
      ];

      for (let i = 0; i < cfs.length; i++) {
        const cf = cfs[i];
        chartData.push([
          i,
          func.pennyRound(cf.grossRent),
          func.pennyRound(cf.otherIncome),
          func.pennyRound(cf.requiredProfit),
          func.pennyRound(parseFloat(cf.repairs.substring(1))),
          func.pennyRound(parseFloat(cf.capital.substring(1))),
        ]);
      }

      console.log(chartData);
      return chartData;
    },
    cashflowSeries(out, overrides, params) {
      if ($.isEmptyObject(params)) {
        params = {
          rentGrowth: 5,
          maintGrowth: 5,
          appreciation: 2,
        };
      }

      return af.cashflowSeries(out, overrides, params);
    },
    cashflow(a, b) {
      return af.cashflow(a, b);
    },
    renderStr(str) {
      return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
    show(ob) {
      const id = ob.id;
      if (this.showId == id) {
        this.showId = null;
        this.showAll = true;
        this.detailView = null;
      } else {
        this.detailView = ob;
        this.showId = id;
        this.showAll = false;
      }
    },
    fetchData() {
      let me = this;
      let id = this.$route.params.id;
      if (id) {
        me.loading = true;

        $.get("/load?q=" + id, function (data) {
          //me.dto = JSON.parse(data);
          me.dto = data;
          console.log(data);
          me.loading = false;
        });
      } else {
        me.dto = {};
        me.loading = false;
      }
    },
  },
  computed: {
    resultSet: function () {
      return func.analyzeAll(this.dto);
    },
    profits: function () {
      console.log(this.dto);
      console.log(this.dto["requiredProfit"]);
      return this.dto ? func.splitOrBracket(this.dto.requiredProfit) : [];
    },
  },
  data: function () {
    return {
      items: [],
      loading: true,
      showAll: true,
      detailView: null,
      showId: null,
      dto: {},
      testData: {},
    };
  },
};
</script>
