import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Inventory from "../views/Inventory.vue";
import Input from "../views/Input.vue";
import Analysis from "../views/Analysis.vue";
import Parcels from "../views/Parcels.vue";
import Chart from "@/components/Chart.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { name: "inventory", path: "/", component: Inventory },
  { name: "new", path: "/new", component: Input },
  { name: "edit", path: "/load/:id", component: Input },
  { name: "calc", path: "/calc/:id", component: Analysis },
  { name: "chart", path: "/chart", component: Chart },
  { name: "parcels", path: "/parcels", component: Parcels },
];

const router = new VueRouter({
  mode: "hash",
  //base: process.env.BASE_URL,
  routes,
});

export default router;
