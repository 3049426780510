<template>
  <div id="input">
    <div v-if="loading">Loading...</div>
    <div v-else>
      <img v-bind:src="dto.imgsrc" class="imgRight" />
      <form v-on:submit.prevent>
        <h3>Summary</h3>
        <input
          id="newCopy"
          class="check"
          type="checkbox"
          v-model="dto.newCopy"
        />
        <label for="newCopy" class="check">Create new copy?</label>

        <label for="name">Report Name:</label>
        <input name="name" v-model="dto.name" />

        <label for="address">Address:</label>
        <input id="address" name="address" v-model="dto.address" />

        <label for="description">Description:</label>
        <textarea
          rows="4"
          cols="50"
          name="description"
          v-model="dto.description"
        >
        </textarea>

        <label for="notes">Notes:</label>
        <textarea rows="6" cols="50" name="description" v-model="dto.notes">
        </textarea>

        <label for="mls">MLS:</label>
        <input name="mls" v-model="dto.mls" />

        <label for="source">Web Address/Source:</label>
        <input name="source" v-model="dto.source" />

        <label for="rlink">Rentometer Link:</label>
        <input id="rlink" name="rlink" v-model="dto.rlink" />

        <label for="imgsrc">Image Source:</label>
        <input name="imgsrc" v-model="dto.imgsrc" />

        <label for="imgsrc">Asking:</label>
        <input name="asking" v-model="dto.asking" />

        <h3>Purchase Details:</h3>
        <NumInput label="Closing Costs:" v-model="dto.closingCosts" />

        <DpInput
          label="Down Payment (Include $ or %):"
          v-model="dto.downPayment"
        />

        <NumInput label="Interest Rate %:" v-model="dto.interestRate" />

        <NumInput label="Term (years):" v-model="dto.term" />

        <NumInput label="Rehab Total:" v-model="dto.rehab" />

        <h3>Income:</h3>
        <div>
          <span id="SFH" class="butn" v-on:click="sfh">SFH</span>
          <span id="2pl" class="butn" v-on:click="pl2">Duplex</span>
          <span id="3pl" class="butn" v-on:click="pl3">3 Plex</span>
          <span id="4pl" class="butn" v-on:click="pl4">4 Plex</span>
        </div>

        <ArrInput label="Gross Rent:" v-model="dto.grossRent" />
        <NumInput label="Other Income:" v-model="dto.otherIncome" />

        <h3>Fixed Landlord Expenses:</h3>
        <NumInput
          label="Electricity:"
          name="electricity"
          v-model="dto.electricity"
        />

        <NumInput
          label="Water & Sewer:"
          name="waterSewer"
          v-model="dto.waterSewer"
        />

        <NumInput label="Garbage:" name="garbage" v-model="dto.garbage" />

        <NumInput label="HOA:" v-model="dto.hoa" />

        <NumInput label="Monthly Insurance:" v-model="dto.monthlyInsurance" />

        <NumInput label="Flood Insurance:" v-model="dto.floodInsurance" />

        <NumInput label="Other Insurance:" v-model="dto.otherInsurance" />

        <NumInput
          label="Current Property Taxes:"
          v-model="dto.currentPropertyTaxes"
        />

        <NumInput
          label="Estimated Property Taxes:"
          v-model="dto.estimatedPropertyTaxes"
        />

        <NumInput
          label="Lawn Care/Snow Removal:"
          name="lawnCare"
          v-model="dto.lawnCare"
        />

        <h3>Variable Landlord Expenses</h3>
        <NumInput label="Vacancy %:" name="vacancy" v-model="dto.vacancy" />

        <DpInput
          label="Repairs and Maintenance $ or %:"
          name="repairs"
          v-model="dto.repairs"
        />

        <br />
        (1 unit = $183, 2 unit = $209, 3 unit $235, 4 unit $261)
        <DpInput
          label="Capital Expenditures $ or %:"
          name="capital"
          v-model="dto.capital"
        />

        <NumInput
          label="Management %"
          name="management"
          v-model="dto.management"
        />

        <h3>Assumptions</h3>
        <ArrInput
          label="Required Monthly Profit ($)"
          name="requiredProfit"
          v-model="dto.requiredProfit"
        />

        <br />

        <button v-on:click="analyze()" :disabled="!validForm">Analyze</button>
      </form>
    </div>
  </div>
</template>

<script language="javascript">
/* eslint-disable @typescript-eslint/no-this-alias */
import Vue from "vue";
import $ from "jquery";
import functions from "../modules/functions.js";
import DpInput from "@/components/DpInput.vue";
import NumInput from "@/components/NumInput.vue";
import ArrInput from "@/components/ArrInput.vue";

export default Vue.extend({
  name: "Input",
  components: {
    DpInput,
    NumInput,
    ArrInput,
  },
  watch: {
    $route(from, to) {
      this.fetchData();
      //setTimeout(chargeInputForm, 100);
    },
  },
  created() {
    this.fetchData();
    //setTimeout(chargeInputForm, 100);
  },
  computed: {
    validForm: function () {
      return functions.validDto(this.dto);
    },
  },
  methods: {
    testclick: function () {
      console.log("abc");
      alert("abc");
    },
    sfh: function () {
      console.log(this.dto);
      this.dto.electricity = 0;
      this.dto.waterSewer = 0;
      this.dto.garbage = 0;
      this.dto.lawnCare = 0;
      this.dto.vacancy = 8;
      this.dto.repairs = "$65";
      this.dto.capital = "$183";
      this.dto.management = 10;
      this.dto.requiredProfit = "-100,0,100,200";
      console.log(this.dto);
      // setInterval(() => {
      //   this.dto.electricity++;
      // }, 1000);
    },
    pl2: function () {
      console.log(this.dto);
      this.dto.electricity = 0;
      this.dto.waterSewer = 100;
      this.dto.garbage = 40;
      this.dto.lawnCare = 40;
      this.dto.vacancy = 5;
      this.dto.repairs = "$130";
      this.dto.capital = "$209";
      this.dto.management = 10;
      this.dto.requiredProfit = "-100,0,100,200";
      console.log(this.dto);
    },
    pl3: function () {
      this.dto.electricity = 0;
      this.dto.waterSewer = 150;
      this.dto.garbage = 60;
      this.dto.lawnCare = 40;
      this.dto.vacancy = 5;
      this.dto.repairs = "$195";
      this.dto.capital = "$235";
      this.dto.management = 10;
      this.dto.requiredProfit = "-100,0,100,200,300";
    },
    pl4: function () {
      this.dto.electricity = 0;
      this.dto.waterSewer = 200;
      this.dto.garbage = 80;
      this.dto.lawnCare = 40;
      this.dto.vacancy = 5;
      this.dto.repairs = "$260";
      this.dto.capital = "$261";
      this.dto.management = 10;
      this.dto.requiredProfit = "-100,0,100,200,400";
    },
    analyze: function () {
      let thisid = this.$route.params.id;
      let me = this;
      let copy = JSON.parse(JSON.stringify(me.dto));
      if (copy.newCopy) {
        copy.id = "";
      }

      var settings = {
        url: "/save",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(copy),
      };

      $.ajax(settings)
        .done(function (response) {
          me.$router.push({ name: "calc", params: { id: response.id } });
        })
        .fail(function (xhr, status, error) {
          var errorMessage = xhr.status + ": " + xhr.statusText;
          alert("Error - " + errorMessage);
        });

      //this.$router.push({ name:'calc', params: {id : thisid}} );
    },
    fetchData() {
      let me = this;
      let id = this.$route.params.id;
      if (id) {
        me.loading = true;

        $.get("/load?q=" + id, function (data) {
          me.dto = data;
          me.loading = false;
          //setTimeout(chargeIngputForm, 100);
        });
      } else {
        me.loading = false;
      }
    },
  },
  data: function () {
    return {
      items: [],
      loading: true,
      dto: {
        newCopy: false,
        closingCosts: "",
        downPayment: "",
        interestRate: "",
        term: "",
        rehab: "",
        grossRent: "",
        otherIncome: "",
        electricity: "",
        waterSewer: "",
        garbage: "",
        hoa: "",
        monthlyInsurance: "",
        floodInsurance: "",
        otherInsurance: "",
        currentPropertyTaxes: "",
        estimatedPropertyTaxes: "",
        lawnCare: "",
        vacancy: "",
        capital: "",
        repairs: "",
        management: "",
        requiredProfit: "",
      },
    };
  },
});
</script>
