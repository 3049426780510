function numValid(input) {
  return /^[-]?\d+(\.[0-9]{0,2})?$/.test(input);
}

function dpValid(input) {
  return (
    /^[$]\d+(\.[0-9]{0,2})?$/.test(input) ||
    /^\d+(\.[0-9]{0,2})?[%]$/.test(input)
  );
}

function arrValid(input) {
  return (
    numValid(input) ||
    /^([-]?\d+(\.[0-9]{0,2})?,)+[-]?\d+(\.[0-9]{0,2})?$/.test(input)
  );
}

function validDto(dto) {
  return (
    numValid(dto.closingCosts) &&
    dpValid(dto.downPayment) &&
    numValid(dto.interestRate) &&
    numValid(dto.term) &&
    numValid(dto.rehab) &&
    arrValid(dto.grossRent) &&
    numValid(dto.otherIncome) &&
    numValid(dto.electricity) &&
    numValid(dto.waterSewer) &&
    numValid(dto.garbage) &&
    numValid(dto.hoa) &&
    numValid(dto.monthlyInsurance) &&
    numValid(dto.floodInsurance) &&
    numValid(dto.otherInsurance) &&
    numValid(dto.currentPropertyTaxes) &&
    numValid(dto.estimatedPropertyTaxes) &&
    numValid(dto.lawnCare) &&
    numValid(dto.vacancy) &&
    dpValid(dto.capital) &&
    dpValid(dto.repairs) &&
    numValid(dto.management) &&
    arrValid(dto.requiredProfit)
  );
}

function validBase(validf) {
  return {
    props: ["label", "baseid", "value", "name", "validtype"],
    computed: {
      iid: () => {
        return this.baseid + "-in";
      },
      valid() {
        let input = this.value;
        let out = validf(this.value);
        //console.log(input, "  --> ", out);
        return out;
      },
    },
  };
}

function strip(s) {
  return s.replace(/[^0-9$%.]/g, "");
}

function getMonthlyValue(totalIncome, value, rate) {
  const multRate = rate ? rate : 1;

  value = strip(value);
  if (value.startsWith("$")) {
    return multRate * parseFloat(value.substring(1));
  } else if (value.endsWith("%")) {
    return (
      (totalIncome * parseFloat(value.substring(0, value.length - 1))) / 100
    );
  } else {
    throw "Not sure what this is: " + value;
  }
}

function pf(f) {
  return f ? parseFloat(f) : 0.0;
}

function numerifyDto(dto) {
  dto.electricity = pf(dto.electricity);
  dto.waterSewer = pf(dto.waterSewer);
  dto.garbage = pf(dto.garbage);
  dto.hoa = pf(dto.hoa);
  dto.monthlyInsurance = pf(dto.monthlyInsurance);
  dto.floodInsurance = pf(dto.floodInsurance);
  dto.otherInsurance = pf(dto.otherInsurance);
  dto.estimatedPropertyTaxes = pf(dto.estimatedPropertyTaxes);

  //dto.grossRent = pf(dto.grossRent);
  dto.otherIncome = pf(dto.otherIncome);
  dto.vacancy = pf(dto.vacancy);
  dto.management = pf(dto.management);
  //dto.requiredProfit = pf(dto.requiredProfit);
  dto.interestRate = pf(dto.interestRate);
  dto.rehab = pf(dto.rehab);
  dto.closingCosts = pf(dto.closingCosts);
}

function pennyRound(n) {
  return parseFloat(n.toFixed(2));
}
function analyzeOne(dto, id = null) {
  numerifyDto(dto);
  let fixedExpenses =
    dto.electricity +
    dto.waterSewer +
    dto.garbage +
    dto.hoa +
    dto.monthlyInsurance +
    dto.floodInsurance +
    dto.otherInsurance +
    dto.estimatedPropertyTaxes;
  let monthlyIncome = dto.grossRent + dto.otherIncome;

  let vacancyExpense = (dto.vacancy / 100.0) * monthlyIncome;
  let repairExpense = getMonthlyValue(monthlyIncome, dto.repairs);
  let capitalExpense = getMonthlyValue(monthlyIncome, dto.capital);
  let managementExpense = pennyRound((dto.management / 100) * monthlyIncome);

  let totalVariableExpenses = pennyRound(
    vacancyExpense + repairExpense + capitalExpense + managementExpense
  );

  let mortgagePayment =
    monthlyIncome - totalVariableExpenses - fixedExpenses - dto.requiredProfit;

  let apr = dto.interestRate / 100.0;
  let r = apr / 12;
  let n = 12 * dto.term;
  let presentValueOfLoan = mortgagePayment * ((1 - Math.pow(1 + r, -n)) / r);
  /*console.log(" ---- ");
    console.log("Montly Income: " + monthlyIncome);
    console.log("Required Profit: " + dto.requiredProfit);
    console.log("Mortgage Payment: " + mortgagePayment);
    console.log("Present Value of Loan: " + presentValueOfLoan);*/

  let downPayment;
  let dp = strip(dto.downPayment);
  if (dp.startsWith("$")) {
    downPayment = parseFloat(dto.downPayment.substring(1));
  } else if (dp.endsWith("%")) {
    let pct =
      parseFloat(dto.downPayment.substring(0, dto.downPayment.length - 1)) /
      100.0;

    downPayment = (presentValueOfLoan / (1 - pct)) * pct;
  } else {
    throw "Unknown format: " + dto.downPayment;
  }

  presentValueOfLoan = Math.floor(presentValueOfLoan / 50) * 50;
  downPayment = Math.floor(downPayment / 50) * 50;
  let totalOffer = downPayment + presentValueOfLoan;

  let totalCash = downPayment + dto.rehab + dto.closingCosts;
  let humanOffer = parseInt(totalOffer / 1000).toFixed(0) * 1000;

  let out = {
    dto: dto,
    id: id,
    fixedExpenses: fixedExpenses,
    totalIncome: monthlyIncome,
    vacancyExpense: vacancyExpense,
    repairExpense: repairExpense,
    capitalExpense: capitalExpense,
    totalVariableExpenses: totalVariableExpenses,
    mortgagePayment: mortgagePayment,
    presentValueOfLoan: presentValueOfLoan,
    downPayment: downPayment,
    totalOffer: totalOffer,
    humanOffer: humanOffer,
    totalCash: totalCash,
    cashFlow: dto.requiredProfit,
    yearlyProfit: dto.requiredProfit * 12,
    totalCocReturn: ((dto.requiredProfit * 12) / totalCash) * 100,
    managementExpense: managementExpense,
  };

  return out;
}

function splitOrBracket(value) {
  if (value.toString().includes(",")) {
    return splitNums(value);
  } else {
    let rp = pf(value);
    return [rp - 100, rp, rp + 100];
  }
}

function splitNums(arr) {
  console.log("Splitting ", arr);
  let spl = arr.split(",");
  let out = [];

  for (let i = 0; i < spl.length; i++) {
    out.push(pf(spl[i]));
  }

  return out;
}

function analyzeAll(dto) {
  // save to web server now
  let profits = splitOrBracket(dto.requiredProfit);
  let rents = splitOrBracket(dto.grossRent);

  numerifyDto(dto);

  let id = 0;

  let outList = [];

  for (let rnumI in rents) {
    let rnum = rents[rnumI];
    console.log("rnum ", rnum);
    let results = [];
    for (let numI in profits) {
      let num = profits[numI];
      let tdto = JSON.parse(JSON.stringify(dto));
      tdto.grossRent = rnum;
      tdto.requiredProfit = num;
      numerifyDto(tdto);
      console.log("Gross Rent: ", tdto.grossRent);

      let out = analyzeOne(tdto, id);
      if (out.totalOffer > 1000) {
        results.push(out);
      }

      id++;
    }

    outList.push(results);
  }

  let out = {
    dto: dto,
    resultSet: outList,
  };

  return out;
}

export default {
  validDto: validDto,
  numValid: numValid,
  dpValid: dpValid,
  arrValid: arrValid,
  validBase: validBase,
  analyzeAll: analyzeAll,
  analyzeOne: analyzeOne,
  splitOrBracket: splitOrBracket,
  getMonthlyValue: getMonthlyValue,
  pennyRound: pennyRound,
};
