<template>
  <div>
    <GChart type="LineChart" :data="cdata" :options="odata" />
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export default {
  components: {
    GChart,
  },
  props: ["chartData", "title", "subtitle"],
  methods: {},
  computed: {
    odata() {
      return {
        title: this.title ? this.title : "Chart",
        subtitle: this.subtitle ? this.subtitle : null,
        //chartArea: { width: "100%", left: 10 },
        legend: { position: "none" },
      };
    },
    cdata() {
      console.log("recompute");
      console.log(this.chartData);
      if (this.chartData) {
        console.log("using chart data");
        return this.chartData;
      }

      return [
        ["Year", "Sales", "Expenses", "Profit"],
        ["2010", 1000, 400, 200],
        ["2015", 1170, 460, 250],
        ["2016", 660, 1120, 300],
        ["2017", 1030, 540, 350],
      ];
    },
  },

  data() {
    return {
      // Array will be automatically processed with visualization.arrayToDataTable function
    };
  },
};
</script>
