<template>
  <div id="load">
    <h3>Inventory</h3>
    <div v-if="loading">Loading...</div>
    <div v-else>
      <ul>
        <li><router-link to="/new">New</router-link></li>

        <li v-for="item in items" v-bind:key="item.id">
          <router-link :to="'/calc/' + item.id">{{ item.name }}</router-link>
          <router-link :to="'/load/' + item.id">(edit)</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import $ from "jquery";

export default {
  watch: {
    $route(from, to) {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let me = this;
      me.loading = true;
      me.items = [];
      $.get("/list", function (data) {
        data.sort(function (a, b) {
          return b.mtime - a.mtime;
        });
        me.items = data;
        me.loading = false;
      });
    },
  },
  data: function () {
    return {
      items: [],
      loading: true,
    };
  },
};
</script>

<style src="../../public/calc.css" />
